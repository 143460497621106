/*
TODO:
- Add to Atomic Library and remove from here after development
- Naming conventions for colors
*/

:root {
  --primary-color: #000000;
  --secondary-color: #ffffff;

  --primary-font-color: #000000;
  --secondary-font-color: #ffffff;
  --tertiary-font-color: #747676;

  --primary-background-color: #ffffff;
  --secondary-background-color: #000000;

  --primary-border-color: #000000;
  --secondary-border-color: #d8dada;

  --error-input-border-color: #cd040b;
  --error-input-background-color: #e15145;

  --color-warning-state: #ed7000;
  --color-safe-state: #57c888;
  --color-dark-red: #cd040b;

  /* Verizon specific colors */
  --verizon-primary-hover: #60676c;
  --verizon-secondary-hover: #e7e7e8;
  --verizon-primary-focus: #484d51;
  --veirzon-secondary-focus: #ceced0;
  --verizon-cool-gray1: #f6f6f6;
  --verizon-cool-gray6: #747676;
}

input[type='checkbox'] {
  cursor: pointer !important;
}

input.error {
  border: 1px solid var(--error-input-border-color);
}

@keyframes slidein {
  from {
    margin-left: 100%;
    width: 0%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}

@keyframes slideout {
  from {
    margin-left: 0%;
    width: 100%;
  }

  to {
    margin-left: 100%;
    width: 0%;
  }
}

@keyframes bouncy {
  0% {
    transform: translateY(10px) translateX(0) rotate(0);
  }
  25% {
    transform: translateX(-10px) rotate(-10deg);
  }
  50% {
    transform: translateX(0) rotate(0deg);
  }
  75% {
    transform: translateX(10px) rotate(10deg);
  }
  100% {
    transform: translateY(10px) translateX(0) rotate(0);
  }
}

@keyframes toast-in-right {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
